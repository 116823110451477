<template>
  <v-container id="specail-char-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      title="Special Character Table"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:item.base64="{ item }">
          <img v-bind:src="item.base64" />
        </template>
        <template v-slot:item.system="{ item }">{{
          item.system | getText(systemOption) | Translate
        }}</template>
        <template v-slot:item.char="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.char }}
            <template v-slot:input>
              <v-text-field
                v-model="item.char"
                label="Edit"
                single-line
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="20"
      ></v-pagination>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { systemOption } from "@/definition.js";
export default {
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("sha1"),
        value: "sha1",
        width: "25%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("lbkey"),
        value: "lbkey",
        width: "10%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("base64"),
        value: "base64",
        width: "15%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("system"),
        value: "system",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("char"),
        value: "char",
        width: "10%",
      },
    ],
    systemOption: systemOption
  }),
  computed: {
    ...mapState(["token"]),
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  methods: {
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/system/specailchars/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      config.params["char__isnull"] = true;

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    patchApi(item) {
      // create hn account
      console.log("create spider");
      const currentObj = this;
      let id = item["id"];
      const url =
        process.env.VUE_APP_SERVER_URL + "/system/specailchars/" + id + "/";

      let jsonData = {
        char: item["char"],
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setResult(data) {
      this.totalItems = data.count;
      this.items = data.results;
    },

    getPartData(item) {
      if (item != null) {
        let value = item.substr(0, 50);
        return value;
      }
      return item;
    },
    updateStatus(item) {
      console.log("update ");
      console.log(item);
      this.patchApi(item);
    },
  },
};
</script>
